<template>
  <div class="map">
    <div id="map" ref="map"></div>
    <div class="tankuan" v-show="showTanchuan">
      <div class="topTanchuan">
        <div class="cha" @click="closeTanchuan"></div>
      </div>
      <div class="bottomTanchuan">
        <div class="leftItems">
          <div>乡镇名称:</div>
          <div>本月碳汇量:</div>
          <div>本月碳汇强度:</div>
        </div>
        <div class="rightItems">
          <div>{{ landName }}</div>
          <div>{{ states.num }}</div>
          <div>{{ states.weight }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import mapUrl from "../assets/js/mapUrl";
import * as esri from "esri-loader";
import { onMounted, ref, inject, watch } from "vue";
import shuju from "../assets/js/shuju";
// , reactive,  inject, watch
export default {
  setup() {
    const map = ref(null);
    const DrawModule = ref(null);
    const GraphicModule = ref(null);
    const cunjie = ref(null);
    const yiyuetuceng = ref(null);
    const eryuetuceng = ref(null);
    const sanyuetuceng = ref(null);
    const siyuetuceng = ref(null);
    const wuyuetuceng = ref(null);
    const liuyuetuceng = ref(null);
    const qiyuetuceng = ref(null);
    const bayuetuceng = ref(null);
    const jiuyuetuceng = ref(null);
    const shiyuetuceng = ref(null);
    const shiytiyuetuceng = ref(null);
    const shieryuetuceng = ref(null);
    const whichMonth = inject("whichMonth");
    const showTanchuan = ref(false);
    const landName = ref(0);
    const landState = ref([]);
    const states = ref({ num: 0, weight: 0 });
    const bbbbbbb = inject('bbbbbbb')
    const tanhuihe = ref([])
    onMounted(() => {
      landState.value = shuju["thJaniList"];
      hh();
      esri.loadScript({ url: mapUrl.initjs });
      esri.loadCss(mapUrl.esricss);
      setTimeout(() => {
        initmap();
      }, 50);
    });
    function initmap() {
      esri
        .loadModules([
          "esri/map",
          "esri/layers/ArcGISTiledMapServiceLayer",
          "esri/layers/ArcGISDynamicMapServiceLayer",
          "esri/geometry/Point",
          "esri/graphic",
          // "esri/symbols/PictureMarkerSymbol",
          // "esri/symbols/SimpleFillSymbol",
          "esri/symbols/SimpleLineSymbol",
          // "esri/layers/GraphicsLayer",
          "esri/tasks/QueryTask",
          "esri/tasks/query",
          "esri/layers/ImageParameters",
          // "esri/SpatialReference",
          // "esri/tasks/GeometryService",
          // "esri/tasks/ProjectParameters",
          // "esri/geometry/Polygon",
          "esri/Color",
          "esri/toolbars/draw",
          // "dojo/domReady!",
          "esri/symbols/TextSymbol",
          "esri/symbols/Font",
        ])
        .then(
          ([
            Map,
            ArcGISTiledMapServiceLayer,
            ArcGISDynamicMapServiceLayer,
            Point,
            Graphic,
            // PictureMarkerSymbol,
            // SimpleFillSymbol,
            SimpleLineSymbol,
            // GraphicsLayer,
            QueryTask,
            Query,
            ImageParameters,
            // SpatialReference,
            // GeometryService,
            // ProjectParameters,
            // Polygon,
            Color,
            Draw,
            TextSymbol,
            Font,
          ]) => {
            // 实例化地图
            DrawModule.value = Draw;
            GraphicModule.value = Graphic;
            map.value = new Map("map", {
              slider: false, // 缩放按钮
              logo: false,
              maxZoom: 10,
              minZoom: 0,
              zoom: 0,
            });
            map.value.disableDoubleClickZoom();
            let point = new Point({
              x: 458710.82025249384,
              y: 3390000.6527791307,
              spatialReference: {
                wkid: 4549, // 地理坐标系
              },
            });
            var imageParameters = new ImageParameters();
            imageParameters.format = "PNG32";
            map.value.addLayer(new ArcGISTiledMapServiceLayer(mapUrl.ditu), 1);
            cunjie.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.new_cunjie,
              2
            );
            map.value.addLayer(cunjie.value, 2);
            map.value.on("click", (e) => {
              map.value.graphics.clear();
              let symbol = new SimpleLineSymbol(
                SimpleLineSymbol.STYLE_SOLID,
                new Color([255, 0, 0]),
                2
              );
              let query = new Query(); // 加载查询
              query.returnGeometry = true; // 需要返回Geometry
              query.outFields = ["*"]; // 需要返回的字段q
              query.geometry = e.mapPoint;
              let queryTask = new QueryTask(`${mapUrl.new_cunjie}/0`);
              queryTask.execute(query, (res) => {
                if (res.features.length > 0) {
                  for (let i = 0; i < res.features.length; i++) {
                    let fea = res.features[i];
                    let textSymbol = new TextSymbol(
                      res.features[i].attributes.NAME
                    );

                    textSymbol.setColor(new Color([0, 0, 0]));
                    var font = new Font();
                    font.setSize("25pt");
                    font.setWeight(Font.WEIGHT_BOLDER);
                    textSymbol.setFont(font);
                    fea.setSymbol(symbol);
                    let graph = new Graphic(fea.geometry, textSymbol);
                    map.value.graphics.add(graph);
                    //把查询结果添加到map.graphics中进行显示
                    map.value.graphics.add(fea);
                    map.value.setExtent(fea.geometry.getExtent().expand(0)); //设置地图的视图范围
                    setTimeout(() => {
                      map.value.infoWindow.setTitle();
                      map.value.infoWindow.setContent(getPopUpData(fea));
                      // 把信息模板显示在中心点上
                      map.value.infoWindow.show(
                        fea.geometry.getExtent().getCenter()
                      );
                      map.value.infoWindow.resize(250, 200);
                    }, 500);
                  }
                }
              });
            });
            yiyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.yiyuetuceng
            );
            eryuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.eryuetuceng
            );
            sanyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.sanyuetuceng
            );
            siyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.siyuetuceng
            );
            wuyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.wuyuetuceng
            );
            liuyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.liuyuetuceng
            );
            qiyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.qiyuetuceng
            );
            bayuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.bayuetuceng
            );
            jiuyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.jiuyuetuceng
            );
            shiyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.shiyuetuceng
            );
            shiytiyuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.shiytiyuetuceng
            );
            shieryuetuceng.value = new ArcGISDynamicMapServiceLayer(
              mapUrl.shieryuetuceng
            );
            map.value.addLayer(yiyuetuceng.value, 3);
            map.value.centerAt(point);
          }
        );
    }
    function getPopUpData(fea) {
      for (let i = 0; i < landState.value.length; i++) {
        if (landState.value[i]["thNname"] == fea["attributes"]["NAME"]) {
          if (bbbbbbb.value == false) {
            return `
                <table class="teaNewTable" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="text-align: right;width: 100px;">乡镇名称：</td>
                        <td style="text-align: left;width: 220px;">${landState.value[i]["thNname"]}</td>
                    </tr>
                     <tr>
                        <td style="text-align: right;width: 100px;">本月碳汇量:</td>
                        <td style="text-align: left;width: 220px;">${landState.value[i]["thVale"]} t</td>
                    </tr>
                    
                    <tr>
                        <td style="text-align: right;width: 100px;">本月碳汇强度:</td>
                        <td style="text-align: left;width: 220px;">${landState.value[i]["thqj"]} t/hm²</td>
                    </tr>
                    
                </table>
                `;
          } else {
            return `
                <table class="teaNewTable" cellpadding="0" cellspacing="0">
                    <tr>
                        <td style="text-align: right;width: 100px;">乡镇名称：</td>
                        <td style="text-align: left;width: 220px;">${landState.value[i]["thNname"]}</td>
                    </tr>
                     <tr>
                        <td style="text-align: right;width: 100px;">本月碳汇量:</td>
                        <td style="text-align: left;width: 220px;">${landState.value[i]["thVale"]} t</td>
                    </tr>
                                        <tr>
                        <td style="text-align: right;width: 100px;">往月碳汇量总和:</td>
                        <td style="text-align: left;width: 220px;">${tanhuihe.value[i]} t</td>
                    </tr>
                    <tr>
                        <td style="text-align: right;width: 100px;">本月碳汇强度:</td>
                        <td style="text-align: left;width: 220px;">${landState.value[i]["thqj"]} t/hm²</td>
                    </tr>
                    
                </table>
                `;
          }
        }
      }
      return "暂未统计该乡镇";
    }
    function closeTanchuan() {
      showTanchuan.value = false;
    }
    function hh() {
      for (let i in shuju.thJaniList) {
        tanhuihe.value.push(shuju.thJaniList[i].thVale);
      }
      console.log(tanhuihe.value)
    }
    function eryue() {
      for (let i in shuju.thFebList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thFebList[i].thVale * 1;
      }
    }
    function sanyue() {
      eryue();
      for (let i in shuju.thMarList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thMarList[i].thVale * 1;
      }
    }
    function siyue() {
      sanyue();
      for (let i in shuju.thAprList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thAprList[i].thVale * 1;
      }
    }
    function wuyue() {
      siyue();
      for (let i in shuju.thMayList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thMayList[i].thVale * 1;
      }
    }
    function liuyue() {
      wuyue();
      for (let i in shuju.thJuneList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thJuneList[i].thVale * 1;
      }
    }
    function qiyue() {
      liuyue();
      for (let i in shuju.thJulyList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thJulyList[i].thVale * 1;
      }
    }
    function bayue() {
      qiyue();
      for (let i in shuju.thAugustList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thAugustList[i].thVale * 1;
      }
    }
    function jiuyue() {
      bayue();
      for (let i in shuju.thSeptemberList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thSeptemberList[i].thVale * 1;
      }
    }
    function shiyue() {
      jiuyue();
      for (let i in shuju.thOctoberList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thOctoberList[i].thVale * 1;
      }
    }
    function shiyiyue() {
      shiyue();
      for (let i in shuju.thNovemberList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thNovemberList[i].thVale * 1;
      }
    }
    function shieryue() {
      shiyiyue();
      for (let i in shuju.thDecemberList) {
        tanhuihe.value[i] =
        tanhuihe.value[i] * 1 + shuju.thDecemberList[i].thVale * 1;
      }
    }
    watch(whichMonth, (newValue, oldValue) => {
      map.value.graphics.clear();
      if (oldValue == 1) {
        map.value.removeLayer(yiyuetuceng.value);
      } else if (oldValue == 2) {
        map.value.removeLayer(eryuetuceng.value);
      } else if (oldValue == 3) {
        map.value.removeLayer(sanyuetuceng.value);
      } else if (oldValue == 4) {
        map.value.removeLayer(siyuetuceng.value);
      } else if (oldValue == 5) {
        map.value.removeLayer(wuyuetuceng.value);
      } else if (oldValue == 6) {
        map.value.removeLayer(liuyuetuceng.value);
      } else if (oldValue == 7) {
        map.value.removeLayer(qiyuetuceng.value);
      } else if (oldValue == 8) {
        map.value.removeLayer(bayuetuceng.value);
      } else if (oldValue == 9) {
        map.value.removeLayer(jiuyuetuceng.value);
      } else if (oldValue == 10) {
        map.value.removeLayer(shiyuetuceng.value);
      } else if (oldValue == 11) {
        map.value.removeLayer(shiytiyuetuceng.value);
      } else if (oldValue == 12) {
        map.value.removeLayer(shieryuetuceng.value);
      }

      if (newValue == 1) {
        map.value.infoWindow.hide();
        map.value.addLayer(yiyuetuceng.value);
        landState.value = shuju["thJaniList"];
     
      } else if (newValue == 2) {
        map.value.infoWindow.hide();
        map.value.addLayer(eryuetuceng.value);
        landState.value = shuju["thFebList"];
        eryue();
      } else if (newValue == 3) {
        map.value.infoWindow.hide();
        map.value.addLayer(sanyuetuceng.value);
        landState.value = shuju["thMarList"];
        sanyue();
      } else if (newValue == 4) {
        map.value.infoWindow.hide();
        map.value.addLayer(siyuetuceng.value);
        landState.value = shuju["thAprList"];
        siyue();
      } else if (newValue == 5) {
        map.value.infoWindow.hide();
        map.value.addLayer(wuyuetuceng.value);
        landState.value = shuju["thMayList"];
        wuyue();
      } else if (newValue == 6) {
        map.value.infoWindow.hide();
        map.value.addLayer(liuyuetuceng.value);
        landState.value = shuju["thJuneList"];
        liuyue();
      } else if (newValue == 7) {
        map.value.infoWindow.hide();
        map.value.addLayer(qiyuetuceng.value);
        landState.value = shuju["thJulyList"];
        qiyue();
      } else if (newValue == 8) {
        map.value.infoWindow.hide();
        map.value.addLayer(bayuetuceng.value);
        landState.value = shuju["thAugustList"];
        bayue();
      } else if (newValue == 9) {
        map.value.infoWindow.hide();
        map.value.addLayer(jiuyuetuceng.value);
        landState.value = shuju["thSeptemberList"];
        jiuyue();
      } else if (newValue == 10) {
        map.value.infoWindow.hide();
        map.value.addLayer(shiyuetuceng.value);
        landState.value = shuju["thOctoberList"];
        shiyue();
      } else if (newValue == 11) {
        map.value.infoWindow.hide();
        map.value.addLayer(shiytiyuetuceng.value);
        landState.value = shuju["thNovemberList"];
        shiyiyue();
      } else if (newValue == 12) {
        map.value.infoWindow.hide();
        map.value.addLayer(shieryuetuceng.value);
        landState.value = shuju["thDecemberList"];
        shieryue();
      }
    });

    return {
      map,
      whichMonth,
      cunjie,
      showTanchuan,
      closeTanchuan,
      landName,
      states,
    };
  },
};
</script>
  
<style>
.map {
  position: absolute;
  width: 99vw;
  height: 99vh;
}
.tankuan {
  position: absolute;
  left: 800px;
  top: 200px;
  width: 250px;
  height: 100px;
  border: 1px solid #2811bf;
  border-bottom: 3px solid #2811bf;
}
.topTanchuan {
  position: relative;
  background-color: rgba(25, 9, 134, 0.8);
  border-bottom: 1px solid #2811bf;
  width: 100%;
  height: 20%;
}
.bottomTanchuan {
  background-color: rgba(59, 100, 99, 0.6);
  width: 100%;
  height: 80%;
  display: flex;
}
.cha {
  background-image: url("../assets/cha.png");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  float: right;
  cursor: pointer;
}
.leftItems {
  height: 50px;
  width: 42%;
  color: white;
  font-size: 15px;
  text-align: right;
}
.leftItems > div {
  margin-top: 4px;
}
.rightItems {
  height: 50px;
  width: 54%;
  color: white;
  font-size: 15px;
}
.rightItems > div {
  margin-top: 4px;
  margin-left: 4%;
}
.teaNewTable {
  width: 100%;
  text-align: center;
  /*background-color: rgba(190, 190, 175, 0.5);*/
  /*line-height: 30px;*/
}
.teaNewTable td {
  white-space: nowrap;
  padding: 2px;
  font-size: 15px;
  background-color: rgba(0, 0, 0, 0) !important;
}
.esriPopup .titlePane {
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.esriPopup .contentPane {
  width: fit-content !important;
  height: fit-content !important;
  margin: 0 auto !important;
  padding: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  color: white !important;
}
.esriPopup .actionsPane {
  padding: 0 !important;
}
.sizer {
  /* width: 450px!important; */
  background-color: rgba(0, 0, 0, 0.5) !important;
  color: white;
  overflow: hidden;
  border: 1px solid rgb(39 13 204 / 92%);
  background: linear-gradient(
    180deg,
    rgba(0, 180, 220, 0.3),
    rgba(0, 180, 220, 0.1),
    rgba(0, 180, 220, 0.1),
    rgba(0, 180, 220, 0.3)
  );
  box-shadow: 0 0 2rem rgba(0, 180, 220, 0.1) inset;
}
.outerPointer {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
.maximize {
  display: none;
}
</style>
  