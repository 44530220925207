<template>
  <div id="app">
    <leftItem></leftItem>
    <centerMap></centerMap>
    <rightItem></rightItem>
  </div>
</template>

<script>
import centerMap from "./components/centerMap.vue";
import leftItem from "./components/leftItem.vue";
import rightItem from "./components/rightItem.vue";
import {onMounted,ref,provide} from "vue";
export default {
  name: "App",
  components: {
    centerMap,
    leftItem,
    rightItem
  },
  setup() {
    const whichMonth= ref(1);
    const bbbbbbb= ref(true);
    provide('whichMonth',whichMonth)
    provide('bbbbbbb',bbbbbbb)
    onMounted(() => {

    });
    return {
      whichMonth,
      bbbbbbb
    };
  },
};
</script>

<style>
#app {
  display: flex;
}
</style>
