<template>
  <div class="rightItem" id="rightItem">
    <div class="image1" id="image1" @click="leiji">累计</div>
    <div class="image2" id="image2" @click="danyue">当月</div>
  </div>
</template>
<script>
import { onMounted, inject } from "vue";
// , reactive,  inject, watch
export default {
  setup() {
    const bbbbbbb = inject("bbbbbbb");
    onMounted(() => {});
    function leiji() {
      bbbbbbb.value = false;
      document.getElementById("image1").style.color ='#30abe7'
      document.getElementById("image2").style.color ='white'
    }
    function danyue() {
      bbbbbbb.value = true;
      document.getElementById("image1").style.color ='white'
      document.getElementById("image2").style.color ='#30abe7'
    }
    return {
      leiji,
      danyue,
      bbbbbbb
    };
  },
};
</script>
<style>
.rightItem {
  width: 100px;
  height: 300px;
  position: absolute;
  right: 10vw;
  bottom: 5vh;
}
.image1 {
  width: 95px;
  height: 105px;
  background-image: url(../assets/tupian.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 100px;
  font-size: 1vw;
  font-weight: 500;
  color: white;
  cursor: pointer;
}
.image2 {
  width: 95px;
  height: 105px;
  background-image: url(../assets/tupian.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  line-height: 100px;
  font-size: 1vw;
  font-weight: 500;
  color: white;
  cursor: pointer;
  margin-top: 20px;
  color: #30abe7;
}
</style>
  