const mapIP = `gis.fttrs.com` /* 云服务器ip */
const initjs = `https://${mapIP}/3.27/init.js` // 云服务器部署的js
const esricss = `https://${mapIP}/3.27/esri/css/esri.css` // 云服务器部署的js

const PrintTask = `https://${mapIP}/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task` // 设置转换图片路径
const geomeService = `https://${mapIP}/arcgis/rest/services/Utilities/Geometry/GeometryServer` // 获取图斑经纬度

export default {
  initjs,
  esricss,
  PrintTask,
  geomeService,
  // xzbj: `${serverIP}图层数据/乡镇边界/MapServer`, // 乡镇边界
  // ajcj: `${serverIP}图层数据/安吉村界/MapServer`, // 安吉村界
  // xlx: `${serverIP}图层数据/溪龙乡/MapServer`, // 溪龙乡切片
  // xlx_ditu: `${serverIP}图层数据/溪龙乡底图/MapServer`, // 溪龙乡底图切片
  // xlx_wq: `${serverIP}未切/溪龙乡未切/MapServer`, // 溪龙乡未切片
  // anji_data: `${serverIP}未切/安吉茶园数据/MapServer`, // 安吉茶园所有数据
  // anji_data_yanshi: `${serverIP}演示/有钱博士/MapServer`, // 安吉茶园所有数据

  /* 图层 */
  ditu: `https://${mapIP}/arcgis/rest/services/newmap/newditu/MapServer`, // 影像图，安吉底图()1用
  anji_slice: `https://${mapIP}/arcgis/rest/services/newmap/anji_slice/MapServer`, // 切片茶园
  nongbaodi_slice: `https://${mapIP}/arcgis/rest/services/map/nongbaodi_slice/MapServer`, // 切片农保地
  map_cunjie_slice_name: `https://${mapIP}/arcgis/rest/services/map/map_cunjie_slice_name/MapServer`, // 切片农保地
  new_cunjie: `https://${mapIP}/arcgis/rest/services/newmap/new_cunjie/MapServer`, // 切片农保地()2用
  aj_th_1_220122: `https://${mapIP}/arcgis/rest/services/newmap/aj_th_1_220122/MapServer`, // 没变化
  dpjd_th_4_220118: `https://${mapIP}/arcgis/rest/services/newmap/dpjd_th_4_220118/MapServer`,
  gcs_hw_4_220117: `https://${mapIP}/arcgis/rest/services/newmap/gcs_hw_4_220117/MapServer`, //
  yiyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/yiyuetuceng/MapServer`, //
  eryuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/eryuetuceng/MapServer`, //3
  sanyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/sanyuetuceng/MapServer`, //
  siyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/siyuetuceng/MapServer`, //
  wuyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/wuyuetuceng/MapServer`, //
  liuyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/liuyuetuceng/MapServer`, //
  qiyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/qiyuetuceng/MapServer`, //
  bayuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/bayuetuceng/MapServer`, //
  jiuyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/jiuyuetuceng/MapServer`, //
  shiyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/shiyuetuceng/MapServer`, //
  shiytiyuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/shiytiyuetuceng/MapServer`, //
  shieryuetuceng: `https://${mapIP}/arcgis/rest/services/yuefentuceng/shieryuetuceng/MapServer`, //
}