<template>
  <div class="bottomBtn">
    <div class="bottmBtnYear">
      <input
        type="text"
        v-model="yearNa"
        maxlength="4"
        oninput="value=value.replace(/[^\d]/g,'')"
      />
    </div>
    <div class="bottomBox" @mouseenter="ttomi" @mouseleave="offmi">
      <div
        class="bottomitem"
        v-for="(item, index) in yue"
        :key="index"
        @click="yuexuanz(item, index)"
      >
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="bottomBtnQren" v-if="false">
      <button>确定</button>
    </div>
  </div>
</template>
<script>
import { onMounted, ref,inject } from "vue";
// , reactive,  inject, watch
export default {
  setup() {
    const yearNa = ref("");
    const yue = ref([]);
    const lliitt = ref([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    const itees = ref([1]);
    const whichMonth = inject('whichMonth')
    onMounted(() => {
      yearNa.value = 2021;
      yue.value = 1;
    });
    // 动态列表移入
    async function ttomi() {
      yue.value = lliitt.value;
    }
    async function offmi() {
      yue.value = itees.value;
    }
    async function yuexuanz(e) {
      yue.value = [e];
      itees.value = [e];
      whichMonth.value = e
    }
    return {
      yearNa,
      yue,
      ttomi,
      offmi,
      yuexuanz,
    };
  },
};
</script>
<style>
.leftItem {
  margin-top: 700px;
  margin-left: 100px;
  background-color: rgba(128, 139, 163, 0.7);
  width: 80px;
  height: 60px;
  z-index: 99;
  border-radius: 25px;
  display: flex;
  align-items: center;
}
.month {
  background-color: rgba(32, 52, 62, 0.7);
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
  color: white;
  margin-left: 20px;
}
.bottomBtn {
  position: fixed;
  bottom: 15%;
  left: 2%;
  z-index: 99 !important;
  background-color: rgba(1, 23, 72, 0.5);
  border-radius: 1.5rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
}
.bottmBtnYear {
  width: 60px;
}
.bottmBtnYear > input {
  /* background-color: rgba(253, 253, 253, 0.5); */
  background-color: rgba(1, 23, 29, 0.502);
  border: rgba(0, 0, 0, 0.5) solid 1px;
  width: 110px;
  border-radius: 2rem;
  padding-left: 15px;
  padding-right: 45px;
  height: 2.5rem;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 1) 0.2rem 0.2rem 0.2rem inset;
  color: #fff;
  font-size: 1.2rem;
}
.bottomBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.bottomitem {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0.5rem;
  line-height: 2rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 1) 0.2rem 0.2rem 0.2rem inset;
  border-radius: 2rem;
  background-color: rgba(1, 23, 29, 0.502);
  border: solid 1px rgba(30, 60, 95, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomitem > span {
  color: #fff;
  z-index: 110 !important;
}
.bottomitem:hover {
  box-shadow: none;
  border: solid 1px rgba(169, 171, 173, 0.5);
  background-color: rgba(1, 23, 72, 0.5);
  transform: rotate(-360deg);
  transition: 0.7s;
}
.bottomBtnQren > button {
  width: 100px;
  height: 2.5rem;
  box-sizing: border-box;
  border-radius: 1rem;
  border: none;
  background: radial-gradient(
    rgba(32, 123, 184, 0.5),
    rgba(216, 216, 216, 0.5)
  );
  color: #fff;
}
</style>
  